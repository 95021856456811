<template>
  <MapControl position="top-left">
    <Button
      type="white"
      :icon="icon"
      :title="title"
      :has-shadow="true"
      @click="onClick">
    </Button>
  </MapControl>
</template>

<script>
import MapControl from '@/modules/map/components/controls/MapControl.vue'

export default {
  components: {
    MapControl
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    icon () {
      return this.value ? 'icon-chevron-up' : 'icon-chevron-down'
    },

    title () {
      return this.value ? this.$t('hunt.hidePanelText') : this.$t('hunt.showPanelText')
    }
  },

  methods: {
    onClick () {
      this.$emit('input', !this.value)
    }
  }
}
</script>
