<template>
  <Chat class="playback-chat">
    <ChatMessages v-if="messages.length > 0">
      <ReportPageTabChatBubble
        v-for="message in messages"
        :key="message.id"
        :message="message">
      </ReportPageTabChatBubble>
    </ChatMessages>

    <Paragraph
      v-else
      class="has-text-grey no-messages-sent"
      :text="$t('report.chat.none')">
    </Paragraph>
  </Chat>
</template>

<script>
import ReportPageTabChatBubble from '../ReportPageTabChatBubble.vue'

export default {
  components: {
    ReportPageTabChatBubble
  },

  computed: {
    messages () {
      const elapsedInMs = this.$store.getters['report/playback/getElapsedTimeInMs']
      const messages = this.$store.getters['report/chat/getMessages'] || []

      return messages
        .filter(message => elapsedInMs >= message.createdAt.getTime())
        .slice()
        .sort((a, b) => a.createdAt - b.createdAt)
    }
  }
}
</script>

<style lang="scss">
.playback-chat {
  flex: 1 1 auto;
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
}

p.no-messages-sent {
  padding: 1.5rem;
  margin: 0 !important;
}
</style>
