<template>
  <MapControl position="bottom">
    <div class="playback-controls">
      <div class="level is-mobile">
        <div class="level-left">
          <Button
            class="is-small-touch"
            type="white"
            :icon="playIcon"
            :disabled="isLoading"
            @click="onPlayPause">
          </Button>

          <Button
            class="is-small-touch"
            type="white"
            icon="icon-square"
            :disabled="isLoading"
            @click="onStop">
          </Button>
        </div>

        <div class="level-item">
          <RangeSlider
            v-model="elapsedTime"
            :max="duration"
            :step="1"
            :label="startAndEndTime"
            :disabled="isLoading"
            class="push-left">
          </RangeSlider>

          <InlineLoader v-if="isLoading" size="small" />
        </div>

        <div class="level-right is-hidden-touch">
          <div class="select is-small">
            <select v-model="playbackSpeed">
              <option
                v-for="speed in speeds"
                :key="speed"
                :value="speed">
                {{ speed }}x</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </MapControl>
</template>

<script>
import { mapGetters } from 'vuex'

import MapControl from '@/modules/map/components/controls/MapControl.vue'
import RangeSlider from '@/components/ui/rangeSlider/RangeSlider.vue'

import { REPORT_PLAYBACK_STATUS_STARTED, REPORT_PLAYBACK_STATUS_PAUSED, REPORT_PLAYBACK_STATUS_STOPPED } from '../../../store/reportPlaybackStore'

export default {
  components: {
    MapControl,
    RangeSlider
  },

  data () {
    return {
      speeds: [1, 2, 5, 10, 25, 50],
      timer: null
    }
  },

  computed: {
    ...mapGetters({
      report: 'report/getReport',
      hunt: 'report/getHunt',
      status: 'report/playback/getStatus',
      startTime: 'report/playback/getStartTime',
      duration: 'report/playback/getDuration',
      isLoading: 'report/playback/isLoading'
    }),

    elapsedTime: {
      get () {
        return this.$store.getters['report/playback/getElapsedTime']
      },

      set (elapsedTime) {
        this.$store.dispatch('report/playback/setElapsedTime', elapsedTime)
      }
    },

    playIcon () {
      return this.status === REPORT_PLAYBACK_STATUS_STARTED ? 'icon-pause' : 'icon-play'
    },

    playbackSpeed: {
      get () {
        return this.$store.getters['report/playback/getSpeed']
      },

      set (speed) {
        this.$store.commit('report/playback/setSpeed', speed)

        if (this.timer !== null) {
          this.stopTimer()
          this.startTimer()
        }
      }
    },

    startAndEndTime () {
      if (this.isLoading) return ''

      const dateFormat = this.report.isMultipleDays() ? 'D MMM HH:mm:ss' : 'HH:mm:ss'

      const startTimeInMs = this.startTime * 1000
      const elapsedInMs = this.elapsedTime * 1000
      const durationInMs = this.duration * 1000

      const elapsed = this.$dayjs(startTimeInMs + elapsedInMs).format(dateFormat)
      const endTime = this.$dayjs(startTimeInMs + durationInMs).format(dateFormat)

      return `${elapsed} / ${endTime}`
    }
  },

  destroyed () {
    this.pause()
  },

  methods: {
    onPlayPause () {
      if (this.status === REPORT_PLAYBACK_STATUS_STOPPED || this.status === REPORT_PLAYBACK_STATUS_PAUSED) {
        this.play()
      } else {
        this.pause()
      }
    },

    onStop () {
      this.stop()
    },

    play () {
      this.$store.commit('report/playback/play')
      this.startTimer()
    },

    pause () {
      this.$store.commit('report/playback/pause')
      this.stopTimer()
    },

    stop () {
      this.$store.commit('report/playback/stop')
      this.stopTimer()
    },

    startTimer () {
      this.timer = setInterval(() => {
        this.advanceTime()
      }, 1000 / this.playbackSpeed)
    },

    stopTimer () {
      if (this.timer !== null) {
        clearInterval(this.timer)
        this.timer = null
      }
    },

    advanceTime () {
      if (this.elapsedTime + 1 < this.duration) {
        this.elapsedTime += 1
      } else {
        this.elapsedTime = this.duration
        this.pause()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.playback-controls {
  background-color: white;
  border-radius: 6px;
  padding: 0.5rem;
  margin-bottom: 0 !important;
  touch-action: none; // disable pinch-to-zoom iOS
}
</style>
