<template>
  <Page v-if="!isLoading" class="report-page">
    <Titlebar :title="title" />

    <div class="report-page-container">
      <div class="report-page-content">
        <ReportPageTabs />
        <RouterView class="report-page-tab-content" />
        <ReportPageToolbar @on-close="onClose" />
      </div>

      <div class="report-page-map">
        <ReportPageMap v-if="!isMapFullscreen" />
      </div>
    </div>

    <ReportPageMapDialog v-if="isMapFullscreen" />
  </Page>
</template>

<script>
import { mapGetters } from 'vuex'

import Titlebar from '@/modules/core/components/layout/Titlebar.vue'

import ReportPageTabs from './ReportPageTabs.vue'
import ReportPageToolbar from './ReportPageToolbar.vue'
import ReportPageMap from './map/ReportPageMap.vue'
import ReportPageMapDialog from './map/ReportPageMapDialog.vue'

export default {
  components: {
    Titlebar,

    ReportPageTabs,
    ReportPageMap,
    ReportPageMapDialog,
    ReportPageToolbar
  },

  data () {
    return {
      reportId: this.$route.params.reportId,
      huntId: this.$route.query.huntId,
      from: this.$route.query.from
    }
  },

  computed: {
    ...mapGetters({
      isLoading: 'report/isLoading',
      startsAt: 'report/getStartsAt',
      isMapFullscreen: 'map/settings/isFullscreen'
    }),

    title () {
      const title = this.$store.getters['report/getTitle']
      return title || this.$i18n.t('report.huntingReportDefaultTitle', { date: this.$dayjs(this.startsAt).format('D MMMM') })
    }
  },

  created () {
    this.$store.dispatch('report/open', {
      reportId: this.reportId,
      huntId: this.huntId
    })
  },

  methods: {
    onClose () {
      if (this.from) {
        this.$router.push(this.from)
      } else {
        this.$router.push('/')
      }
    }
  }
}
</script>

<style scoped>
.report-page {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.report-page-container {
  height: calc(100% - 99px);
  display: flex;
  flex-direction: row;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: 1.25rem;
}

.report-page-content {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 0.625rem;
}

.report-page-map {
  width: 50%;
  height: 100%;
  padding-left: 0.625rem;
}

.report-page-tab-content {
  background-color: white;
  padding: 0.75rem;
  flex-grow: 1;
  overflow-y: auto;
}
</style>
