<template>
  <ReportPageMapPlaybackPanelParticipantListItem
    :icon="icon"
    :name="hunter.name"
    :subtitle="checkInMarkerName"
    :selected="selected"
    :active="hunter.active"
    @select="onSelect">
    <AccuracyBars
      v-if="hunter.active"
      :meters="accuracy"
      :title="`${accuracy}m`">
    </AccuracyBars>
  </ReportPageMapPlaybackPanelParticipantListItem>
</template>

<script>
import { mapGetters } from 'vuex'
import { getMarkerTypeIconUrl } from '@/api/map/markers/markers'

import ReportPageMapPlaybackPanelParticipantListItem from './ReportPageMapPlaybackPanelParticipantListItem.vue'
import AccuracyBars from '@/views/hunt/playback/playbackPanel/components/AccuracyBars.vue'

export default {
  components: {
    ReportPageMapPlaybackPanelParticipantListItem,
    AccuracyBars
  },

  props: {
    hunter: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      selectedDevice: 'report/playback/getSelectedDevice'
    }),

    accuracy () {
      return Math.round(this.hunter.accuracy)
    },

    checkInMarkerName () {
      return this.hunter.checkInMarker ? this.hunter.checkInMarker.name : ''
    },

    selected () {
      return this.selectedDevice === this.hunter
    },

    icon () {
      const icons = {
        'inactive': getMarkerTypeIconUrl('hunter_off'),
        'active': getMarkerTypeIconUrl('hunter'),
        'checked-in': getMarkerTypeIconUrl('hunter_safe')
      }

      return icons[this.hunter.status]
    }
  },

  methods: {
    onSelect () {
      if (this.hunter.active) {
        this.$store.commit('report/playback/setSelectedDevice', !this.selected ? this.hunter : null)
      }
    }
  }
}
</script>
