<template>
  <ReportPageMapPlaybackPanelParticipantListItem
    :icon="icon"
    :name="gpsDevice.name"
    :selected="selected"
    :active="gpsDevice.active"
    @select="onSelect">
    <span v-if="gpsDevice.active">
      {{ speed }} km/h
    </span>
  </ReportPageMapPlaybackPanelParticipantListItem>
</template>

<script>

import { mapGetters } from 'vuex'
import { getMarkerTypeIconUrl } from '@/api/map/markers/markers'

import ReportPageMapPlaybackPanelParticipantListItem from './ReportPageMapPlaybackPanelParticipantListItem.vue'

export default {
  components: {
    ReportPageMapPlaybackPanelParticipantListItem
  },

  props: {
    gpsDevice: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      selectedDevice: 'report/playback/getSelectedDevice'
    }),

    selected () {
      return this.selectedDevice === this.gpsDevice
    },

    speed () {
      return Math.round(this.gpsDevice.speed)
    },

    icon () {
      const icon = this.gpsDevice.active ? 'dog' : 'dog_off'
      return getMarkerTypeIconUrl(icon)
    }
  },

  methods: {
    onSelect () {
      if (this.gpsDevice.active) {
        this.$store.commit('report/playback/setSelectedDevice', !this.selected ? this.gpsDevice : null)
      }
    }
  }

}
</script>
