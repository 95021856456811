<template>
  <Fragment>
    <div v-if="events.length > 0" class="event-list">
      <div
        v-for="event in events"
        :key="event.id"
        class="box level is-mobile">
        <div class="level-left">
          <img :src="icon(event)" class="push-right" />

          <div>
            <h5 class="title is-5 is-spaced is-marginless">
              {{ games(event) }}
            </h5>

            <p class="subtitle is-6 is-marginless">
              {{ date(event.timestamp) }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <Paragraph
      v-else
      class="has-text-grey no-events"
      :text="$t('report.events.noneOccurredYet')">
    </Paragraph>
  </Fragment>
</template>

<script>
import { mapGetters } from 'vuex'
import { getMarkerTypeIconUrl } from '@/api/map/markers/markers'

export default {
  computed: {
    ...mapGetters({
      elapsedTimeInMs: 'report/playback/getElapsedTimeInMs'
    }),

    events () {
      let events = this.$store.getters['report/events/getAll'] || []
      events = events.filter(this.filterEventsByElapsedTime)
      return events.slice().sort((a, b) => a.timestamp - b.timestamp)
    }
  },

  methods: {
    date (date) {
      const format = this.isMultipleDays() ? 'D MMM HH:mm' : 'HH:mm'
      return this.$dayjs(date).format(format)
    },

    icon (event) {
      return getMarkerTypeIconUrl(event.type)
    },

    isMultipleDays () {
      return false
    },

    games (event) {
      const games = event.games.map(game => game.animalName)
      return games.join(', ')
    },

    filterEventsByElapsedTime (event) {
      return this.elapsedTimeInMs >= event.timestamp
    }
  }
}
</script>

<style lang="scss" scoped>
.event-list {
  flex: 1 1 auto;
  height: 100%;
  overflow-y: auto;
}

.box {
  padding: 0.75rem 1rem !important;
  box-shadow: none !important;
  margin-bottom: 0 !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #f2f2f2;

  img {
    position: relative !important;
  }

  &:first-of-type {
    border-top: 1px solid #f2f2f2;
  }

  &:hover {
    background: #f7f7f7;
  }
}

p.no-events {
  padding: 1.5rem;
  margin: 0 !important;
}
</style>
