<template>
  <RouteTabs class="tab-bar">
    <RouteTab
      to="./general"
      :query="{ huntId: huntId }"
      :name="$t('hunts.hunt')">
    </RouteTab>

    <RouteTab
      v-if="canEdit"
      to="./chat"
      :query="{ huntId: huntId }"
      :name="$t('report.chat.title')">
    </RouteTab>

    <RouteTab
      v-if="canEdit"
      to="./planning"
      :query="{ huntId: huntId }"
      :name="$t('report.invitation.title')">
    </RouteTab>
  </RouteTabs>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      huntId: this.$route.query.huntId
    }
  },

  computed: {
    ...mapGetters({
      canEdit: 'report/canEdit'
    })
  }
}
</script>

<style scoped>
.tab-bar {
  background-color: white;
}
</style>
