<template>
  <MapSettingsPanel>
    <div v-if="isPlaybackAllowed">
      <Divider />

      <FormField :label="$t('map.general.markers')">
        <SegmentedControl
          v-model="visibleMarkers"
          size="small"
          :segments="segments">
        </SegmentedControl>
      </FormField>
    </div>
  </MapSettingsPanel>
</template>

<script>
import MapSettingsPanel from '@/modules/map/components/settings/MapSettingsPanel.vue'

import { REPORT_VISIBLE_MARKERS_AFFECTED, REPORT_VISIBLE_MARKERS_ALL } from '../../../store/reportPlaybackStore'
import { mapGetters } from 'vuex'
import { mapProperty } from '@/common/utils/vuexHelpers'

export default {
  components: {
    MapSettingsPanel
  },

  data () {
    return {
      segments: [{
        title: this.$i18n.t('report.markers.affectedByHunt'),
        value: REPORT_VISIBLE_MARKERS_AFFECTED
      }, {
        title: this.$i18n.t('report.markers.all'),
        value: REPORT_VISIBLE_MARKERS_ALL
      }]
    }
  },

  computed: {
    ...mapGetters({
      isPlaybackAllowed: 'report/canEdit'
    }),

    visibleMarkers: mapProperty('report/playback', 'getVisibleMarkers', 'setVisibleMarkers')
  }
}
</script>
