<template>
  <MapMarker
    :name="marker.name"
    :icon="icon"
    :location="marker.location"
    :visible="marker.visible"
    :animation="animation">
  </MapMarker>
</template>

<script>
import { mapGetters } from 'vuex'
import { getMarkerTypeIconUrl } from '@/api/map/markers/markers'

import MapMarker from '@/modules/map/components/MapMarker.vue'

export default {
  components: {
    MapMarker
  },

  props: {
    marker: {
      type: Object,
      required: true
    }
  },

  inject: [
    'getMap'
  ],

  computed: {
    ...mapGetters({
      events: 'report/events/getAll'
    }),

    icon () {
      return getMarkerTypeIconUrl(this.marker.type)
    },

    isSelected () {
      if (this.events !== null) {
        const markerEvents = this.events.filter(event => event.poiId === this.marker.id)
        for (const marker of markerEvents) {
          if (marker === this.$store.getters['report/events/getSelectedEvent']) {
            return true
          }
        }
      }
      return false
    },

    animation () {
      return this.isSelected ? 'bounce' : null
    }
  },

  watch: {
    async isSelected () {
      if (this.isSelected) {
        const map = await this.getMap()
        map.setCenter(this.marker.location)
      }
    }
  }
}
</script>
