<template>
  <div
    :class="[classObject]"
    class="list-item level is-mobile is-marginless flex-important"
    @click="select">
    <div class="level-left">
      <div class="flex align-center">
        <figure class="image is-32x32 push-right-xs is-hidden-touch">
          <img :src="icon" />
        </figure>

        <div>
          <p class="title is-6 is-marginless">
            {{ name }}
          </p>

          <p v-if="subtitle" class="subtitle is-7 is-marginless">
            {{ subtitle }}
          </p>
        </div>
      </div>
    </div>

    <div class="level-right">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true
    },

    name: {
      type: String,
      default: ''
    },

    subtitle: {
      type: String,
      default: ''
    },

    active: {
      type: Boolean,
      default: false
    },

    selected: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classObject () {
      return {
        'is-dimmed': !this.active,
        'is-clickable is-hoverable': this.active,
        'is-active': this.selected
      }
    }
  },

  methods: {
    select () {
      this.$emit('select')
    }
  }
}
</script>

<style lang="scss" scoped>
.list-item {
  min-height: 3rem;

  .title {
    font-weight: 400;
  }

  &.is-hoverable {
    &:hover {
      background: #f5f5f5;
    }
  }

  &.is-active {
    background: #f5f5f5;
    color: inherit;

    .title {
      color: #eb914e;
    }
  }
}
</style>
