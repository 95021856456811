<template>
  <div>
    <slot />
  </div>
</template>

<script>
/**
 * This component is just a basic wrapper to allow client code to create complex objects
 * with multiple map objects contained within. In order to make sure creationg/destruction works correctly
 * these must be wrapped in a valid element. This makes the code more consistent and allows us in the future
 * to extend it with further features if needed.
 */
export default {
}
</script>
