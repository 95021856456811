<template>
  <MapObject>
    <MapMarker
      :name="gpsDevice.name"
      :location="gpsDevice.location"
      :icon="icon"
      :visible="gpsDevice.visible"
      :animation="animation"
      :clickable="true"
      :anchor="anchor"
      @click="onClick">
    </MapMarker>

    <MapPolyline
      :color="gpsDevice.color"
      :width="2"
      :visible="gpsTrailLength > 0"
      :path="trail">
    </MapPolyline>
  </MapObject>
</template>

<script>
import { mapGetters } from 'vuex'
import { getMarkerTypeIconUrl } from '@/api/map/markers/markers'

import MapObject from '@/modules/map/components/MapObject.vue'
import MapMarker from '@/modules/map/components/MapMarker.vue'
import MapPolyline from '@/modules/map/components/MapPolyline.vue'

export default {
  components: {
    MapObject,
    MapMarker,
    MapPolyline
  },

  props: {
    gpsDevice: {
      type: Object,
      required: true
    }
  },

  inject: ['getMap'],

  data () {
    return {
      map: null
    }
  },

  computed: {
    ...mapGetters({
      markerSize: 'map/markers/size',
      selectedDevice: 'report/playback/getSelectedDevice',
      gpsTrailLength: 'report/playback/getGpsTrailLength',
      elapsedTimeInMs: 'report/playback/getElapsedTimeInMs'
    }),

    icon () {
      return getMarkerTypeIconUrl('dog')
    },

    iconSize () {
      return this.markerSize * 32
    },

    animation () {
      return this.gpsDevice.visible ? 'drop' : null
    },

    selected () {
      return this.selectedDevice === this.gpsDevice
    },

    anchor () {
      return {
        x: this.iconSize / 2,
        y: this.iconSize / 2
      }
    },

    trail () {
      const trail = []

      const trailLengthInMs = this.gpsTrailLength * 1000

      for (const gpsLocation of this.gpsDevice.trail) {
        if (gpsLocation.timestamp <= this.elapsedTimeInMs && gpsLocation.timestamp >= (this.elapsedTimeInMs - trailLengthInMs)) {
          trail.push(gpsLocation.location)
        }

        // If we reach the first location in the future, abort
        if (gpsLocation.timestamp > this.elapsedTimeInMs) {
          break
        }
      }

      trail.push(this.gpsDevice.location)

      return trail
    }
  },

  watch: {
    elapsedTimeInMs () {
      this.centerOnMap()
    },

    selected () {
      if (this.selected) {
        this.centerOnMap()
      }
    }
  },

  async created () {
    this.map = await this.getMap()
  },

  methods: {
    onClick () {
      this.$store.dispatch('report/playback/setSelectDevice', !this.selected ? this.gpsDevice : null)
    },

    centerOnMap () {
      if (this.selected) {
        this.map.setCenter(this.gpsDevice.location)
      }
    }
  }
}
</script>
