<template>
  <MapObject>
    <MapMarker
      :name="name"
      :location="hunter.location"
      :icon="icon"
      :visible="hunter.visible"
      :animation="animation"
      :clickable="true"
      :anchor="anchor"
      @click="onClick">
    </MapMarker>

    <MapPolyline
      :color="'#FA6400'"
      :width="2"
      :visible="hunterTrailLength > 0"
      :path="trail">
    </MapPolyline>
  </MapObject>
</template>

<script>
import { mapGetters } from 'vuex'
import { getMarkerTypeIconUrl } from '@/api/map/markers/markers'

import MapObject from '@/modules/map/components/MapObject.vue'
import MapMarker from '@/modules/map/components/MapMarker.vue'
import MapPolyline from '@/modules/map/components/MapPolyline.vue'

export default {
  components: {
    MapObject,
    MapMarker,
    MapPolyline
  },

  props: {
    hunter: {
      type: Object,
      required: true
    }
  },

  inject: ['getMap'],

  data () {
    return {
      map: null
    }
  },

  computed: {
    ...mapGetters({
      markerSize: 'map/markers/size',
      selectedDevice: 'report/playback/getSelectedDevice',
      hunterTrailLength: 'report/playback/getHunterTrailLength',
      elapsedTimeInMs: 'report/playback/getElapsedTimeInMs'
    }),

    name () {
      return this.hunter.checkInMarker ? this.hunter.name + ' - ' + this.hunter.checkInMarker.name : this.hunter.name
    },

    icon () {
      const icons = {
        'inactive': getMarkerTypeIconUrl('hunter_off'),
        'active': getMarkerTypeIconUrl('hunter'),
        'checked-in': getMarkerTypeIconUrl('hunter_safe')
      }

      return icons[this.hunter.status]
    },

    iconSize () {
      return this.markerSize * 32
    },

    selected () {
      return this.selectedDevice === this.hunter
    },

    animation () {
      return this.hunter.visible ? 'drop' : null
    },

    anchor () {
      return {
        x: this.iconSize / 2,
        y: this.iconSize / 2
      }
    },

    trail () {
      const trail = []

      const trailLengthInMs = this.hunterTrailLength * 1000

      for (const hunterLocation of this.hunter.trail) {
        if (hunterLocation.timestamp <= this.elapsedTimeInMs && hunterLocation.timestamp >= (this.elapsedTimeInMs - trailLengthInMs)) {
          trail.push(hunterLocation.location)
        }

        // If we reach the first location in the future, abort
        if (hunterLocation.timestamp > this.elapsedTimeInMs) {
          break
        }
      }

      trail.push(this.hunter.location)

      return trail
    }
  },

  watch: {
    elapsedTimeInMs () {
      this.centerOnMap()
    },

    selected () {
      if (this.selected) {
        this.centerOnMap()
      }
    }
  },

  async created () {
    this.map = await this.getMap()
  },

  methods: {
    onClick () {
      this.$store.commit('report/playback/setSelectedDevice', !this.selected ? this.hunter : null)
    },

    centerOnMap () {
      if (this.selected) {
        this.map.setCenter(this.hunter.location)
      }
    }
  }
}
</script>
