<template>
  <Portal to="modal">
    <div class="modal is-active">
      <div class="modal-background"></div>

      <div class="modal-content">
        <ReportPageMap />
      </div>
    </div>
  </Portal>
</template>

<script>
import ReportPageMap from './ReportPageMap.vue'

export default {
  components: {
    ReportPageMap
  }
}
</script>

<style scoped>
  .modal-content {
    background: white;
    height: calc(100vh - 5rem);
    width: calc(100vw - 5rem);
    display: flex;
    border-radius: 0.5rem;
    transition: all 0.15s ease-out;
  }
</style>
