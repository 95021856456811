<template>
  <Map>
    <ReportPageMapSettingsPanel slot="map-settings-panel" />

    <ReportPageMapPlaybackControls v-if="isPlaybackAllowed" />
    <ReportPageMapPlaybackButton v-if="isPlaybackAllowed" v-model="playbackPanelVisible" />
    <ReportPageMapPlaybackPanel v-if="playbackPanelVisible" />

    <MapBoundary
      v-if="boundary !== null"
      :coordinates="boundary.coordinates">
    </MapBoundary>

    <ReportPageMapHunterMarker
      v-for="hunter in hunters"
      :key="hunter.id"
      :hunter="hunter">
    </ReportPageMapHunterMarker>

    <ReportPageMapGpsDeviceMarker
      v-for="gpsDevice in gpsDevices"
      :key="gpsDevice.id"
      :gpsDevice="gpsDevice">
    </ReportPageMapGpsDeviceMarker>

    <ReportPageMapMarker
      v-for="marker in markers"
      :key="marker.id"
      :marker="marker">
    </ReportPageMapMarker>

    <ReportPageMapEventMarker
      v-for="event in events"
      :key="event.id"
      :event="event">
    </ReportPageMapEventMarker>
  </Map>
</template>

<script>
import { mapGetters } from 'vuex'

import Map from '@/modules/map/components/Map.vue'
import MapBoundary from '@/modules/map/components/MapBoundary.vue'

import ReportPageMapMarker from './ReportPageMapMarker.vue'
import ReportPageMapEventMarker from './ReportPageMapEventMarker.vue'
import ReportPageMapHunterMarker from './ReportPageMapHunterMarker.vue'
import ReportPageMapGpsDeviceMarker from './ReportPageMapGpsDeviceMarker.vue'

import ReportPageMapSettingsPanel from './ReportPageMapSettingsPanel.vue'
import ReportPageMapPlaybackButton from './ReportPageMapPlaybackButton.vue'
import ReportPageMapPlaybackPanel from './ReportPageMapPlaybackPanel.vue'
import ReportPageMapPlaybackControls from './ReportPageMapPlaybackControls.vue'

export default {
  components: {
    Map,
    MapBoundary,
    ReportPageMapPlaybackButton,
    ReportPageMapPlaybackPanel,
    ReportPageMapPlaybackControls,
    ReportPageMapSettingsPanel,
    ReportPageMapMarker,
    ReportPageMapEventMarker,
    ReportPageMapHunterMarker,
    ReportPageMapGpsDeviceMarker
  },

  data () {
    return {
      playbackPanelVisible: false
    }
  },

  computed: {
    ...mapGetters({
      boundary: 'map/boundary/boundary',
      markers: 'report/playback/getMarkers',
      elapsedTime: 'report/playback/getElapsedTime',
      elapsedTimeInMs: 'report/playback/getElapsedTimeInMs',
      hunterLocations: 'report/playback/getHunterLocations',
      gpsLocations: 'report/playback/getGpsLocations',
      isPlaybackAllowed: 'report/canEdit'
    }),

    hunters () {
      // Hunters should only be visible when playback has started
      const hunters = this.$store.getters['report/playback/getHunters'] || []
      return this.elapsedTime > 0 ? hunters : []
    },

    gpsDevices () {
      // GPS devices should only be visible when playback has started
      const gpsDevices = this.$store.getters['report/playback/getGpsDevices'] || []
      return this.elapsedTime > 0 ? gpsDevices : []
    },

    events () {
      let events = this.$store.getters['report/events/getAll'] || []

      if (this.isPlaybackAllowed) {
        // All events should be visible by default but filtered when playback starts
        events = this.elapsedTime > 0 ? events.filter(this.filterEventsByElapsedTime) : events
      }

      return events.filter(event => event.coordinate !== null)
    }
  },

  methods: {
    filterEventsByElapsedTime (event) {
      return this.elapsedTimeInMs >= event.timestamp
    }
  }
}
</script>
