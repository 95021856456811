<template>
  <MapControl position="left" class="playback-panel2">
    <div class="playback-panel-content">
      <Tabs>
        <Tab :name="$t('report.participants.title')" selected>
          <ReportPageMapPlaybackPanelTabParticipants />
        </Tab>

        <Tab :name="$t('report.chat.title')">
          <ReportPageMapPlaybackPanelTabChat />
        </Tab>

        <Tab :name="$t('report.events.title')">
          <ReportPageMapPlaybackPanelTabEvents />
        </Tab>
      </Tabs>
    </div>
  </MapControl>
</template>

<script>
import MapControl from '@/modules/map/components/controls/MapControl.vue'

import ReportPageMapPlaybackPanelTabParticipants from './ReportPageMapPlaybackPanelTabParticipants.vue'
import ReportPageMapPlaybackPanelTabChat from './ReportPageMapPlaybackPanelTabChat.vue'
import ReportPageMapPlaybackPanelTabEvents from './ReportPageMapPlaybackPanelTabEvents.vue'

export default {
  components: {
    MapControl,
    ReportPageMapPlaybackPanelTabParticipants,
    ReportPageMapPlaybackPanelTabChat,
    ReportPageMapPlaybackPanelTabEvents
  }
}
</script>

<style lang="scss">
.playback-panel2 {
  top: 5.5rem !important;
  bottom: 6.5rem !important;

  .tabs {
    margin: 0;
  }

  .tabs-details {
    flex: 1 1 auto;
    height: calc(100% - 3.0625rem);
    overflow-y: hidden;

    > div {
      flex: 1 1 auto;
      height: 100%;
    }
  }
}

.playback-panel-content {
  width: 21.5rem;
  height: 100%;
  background-color: white;
  border-radius: 6px;
}
</style>
