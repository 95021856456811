<script>
/* global google */
export default {
  props: {
    clickable: {
      type: Boolean,
      default: false
    },

    visible: {
      type: Boolean,
      default: true
    },

    color: {
      type: String,
      default: 'black'
    },

    opacity: {
      type: Number,
      default: 1
    },

    width: {
      type: Number,
      default: 2
    },

    path: {
      type: Array,
      default: () => []
    }
  },

  inject: ['getMap'],

  data () {
    return {
      polyline: null,
      map: null
    }
  },

  watch: {
    clickable () {
      this.polyline.setOptions({
        clickable: this.clickable
      })
    },

    visible () {
      this.polyline.setVisible(this.visible)
    },

    color () {
      this.polyline.setOptions({
        strokeColor: this.color
      })
    },

    opacity () {
      this.polyline.setOptions({
        strokeOpacity: this.opacity
      })
    },

    width () {
      this.polyline.setOptions({
        strokeWeight: this.width
      })
    },

    path () {
      this.polyline.setPath(this.path)
    }
  },

  async mounted () {
    this.map = await this.getMap()

    this.polyline = new google.maps.Polyline({
      map: this.map,
      clickable: this.clickable,
      path: this.path,
      strokeColor: this.color,
      strokeOpacity: this.opacity,
      strokeWeight: this.width
    })
  },

  destroyed () {
    if (this.polyline) {
      this.polyline.setMap(null)
    }
  },

  render () {
    return ''
  }
}
</script>
