<template>
  <MapMarker
    :name="name"
    :location="event.coordinate"
    :icon="icon"
    :animation="animation">
  </MapMarker>
</template>

<script>
import MapMarker from '@/modules/map/components/MapMarker.vue'
import { getMarkerTypeIconUrl } from '@/api/map/markers/markers'

export default {
  components: {
    MapMarker
  },

  props: {
    event: {
      type: Object,
      required: true
    }
  },

  inject: [
    'getMap'
  ],

  computed: {
    icon () {
      return getMarkerTypeIconUrl(this.event.type)
    },

    name () {
      const games = this.event.games.map(game => game.animalName)
      return games.join(', ')
    },

    animation () {
      return this.isSelected ? 'bounce' : null
    },

    isSelected () {
      const selectedEvent = this.$store.getters['report/events/getSelectedEvent']
      return selectedEvent === this.event
    }
  },

  watch: {
    async isSelected () {
      if (this.isSelected) {
        const map = await this.getMap()
        map.setCenter(this.event.coordinate)
      }
    }
  }
}
</script>
