<template>
  <div class="playback-panel-participants-tab">
    <Heading size="5" :text="$t('report.participants.title')" />

    <InlineLoader v-if="hunters === null" />

    <ReportPageMapPlaybackPanelParticipantList v-else>
      <ReportPageMapPlaybackPanelHunter
        v-for="hunter in hunters"
        :key="hunter.id"
        :hunter="hunter">
      </ReportPageMapPlaybackPanelHunter>
    </ReportPageMapPlaybackPanelParticipantList>

    <Heading size="5" :text="$t('hunt.dogs')" />

    <InlineLoader v-if="gpsDevices === null" />

    <ReportPageMapPlaybackPanelParticipantList v-if="gpsDevices !== null && gpsDevices.length > 0">
      <ReportPageMapPlaybackPanelGpsDevice
        v-for="gpsDevice in gpsDevices"
        :key="gpsDevice.id"
        :gpsDevice="gpsDevice">
      </ReportPageMapPlaybackPanelGpsDevice>
    </ReportPageMapPlaybackPanelParticipantList>

    <Paragraph
      v-if="gpsDevices !== null && gpsDevices.length === 0"
      class="has-text-grey"
      :text="$t('hunt.noDogsInHunt')">
    </Paragraph>

    <Heading size="5" :text="$t('hunt.trailLength')" />

    <div class="level push-bottom-xs">
      <div class="level-left">
        <Label class="text-is-normal" :label="$t('hunt.hunters')" />
      </div>

      <div class="level-right">
        <ReportPageMapPlaybackPanelTrailLength v-model="hunterTrailLength" />
      </div>
    </div>

    <div class="level">
      <div class="level-left">
        <Label class="text-is-normal" :label="$t('hunt.dogs')" />
      </div>

      <div class="level-right">
        <ReportPageMapPlaybackPanelTrailLength v-model="gpsTrailLength" />
      </div>
    </div>

    <Paragraph class="is-marginless">
      <a href="https://support.wehuntapp.com/en/support/solutions" target="_blank">
        {{ $t('general.help') }} <Icon name="icon-help-circle" />
      </a>
    </Paragraph>
  </div>
</template>

<script>
import ReportPageMapPlaybackPanelParticipantList from './ReportPageMapPlaybackPanelParticipantList.vue'
import ReportPageMapPlaybackPanelHunter from './ReportPageMapPlaybackPanelHunter.vue'
import ReportPageMapPlaybackPanelGpsDevice from './ReportPageMapPlaybackPanelGpsDevice.vue'

import ReportPageMapPlaybackPanelTrailLength from './ReportPageMapPlaybackPanelTrailLength.vue'

export default {
  components: {
    ReportPageMapPlaybackPanelTrailLength,
    ReportPageMapPlaybackPanelParticipantList,
    ReportPageMapPlaybackPanelHunter,
    ReportPageMapPlaybackPanelGpsDevice
  },

  computed: {
    hunterTrailLength: {
      get () {
        return this.$store.getters['report/playback/getHunterTrailLength']
      },

      set (value) {
        this.$store.commit('report/playback/setHunterTrailLength', value)
      }
    },

    gpsTrailLength: {
      get () {
        return this.$store.getters['report/playback/getGpsTrailLength']
      },

      set (value) {
        this.$store.commit('report/playback/setGpsTrailLength', value)
      }
    },

    hunters () {
      const hunters = this.$store.getters['report/playback/getHunters']
      if (hunters === null) {
        return null
      }

      return hunters.slice().sort(this.sortByName)
    },

    gpsDevices () {
      const gpsDevices = this.$store.getters['report/playback/getGpsDevices']
      if (gpsDevices === null) {
        return null
      }

      return gpsDevices.slice().sort(this.sortByName)
    }
  },

  methods: {
    sortByName (a, b) {
      return a.name.localeCompare(b.name)
    }
  }
}
</script>

<style lang="scss" scoped>
.playback-panel-participants-tab {
  height: 100%;
  padding: 1.5rem;
  overflow-y: auto;

  .list-item {
    min-height: 3rem;

    .title {
      font-weight: 400;
    }

    &.is-hoverable {
      &:hover {
        background: #f5f5f5;
      }
    }

    &.is-active {
      background: #f5f5f5;
      color: inherit;

      .title {
        color: #eb914e;
      }
    }
  }
}
</style>
