<template>
  <Toolbar class="report-toolbar">
    <ToolbarItemGroup align="left">
      <ToolbarItem v-if="canEdit">
        <Button
          type="secondary"
          :title="$t('general.edit')"
          @click="onEdit">
        </Button>
      </ToolbarItem>

      <ToolbarItem>
        <Button
          :title="$t('general.close')"
          @click="onClose">
        </Button>
      </ToolbarItem>
    </ToolbarItemGroup>
  </Toolbar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      huntAreaId: this.$route.params.huntAreaId,
      huntId: this.$route.query.huntId
    }
  },

  computed: {
    ...mapGetters({
      userId: 'auth/getUserId',
      report: 'report/getReport',
      hunt: 'report/getHunt',
      participants: 'report/participants/getAll'
    }),

    canEdit () {
      // We have a limitation in HC that won't allow editing a report by a guest after the hunt has finished.
      // So for now disable editing if user is not a member.
      return this.isMember && (this.isAdmin || this.isHuntLeader || this.isParticipant)
    },

    isAdmin () {
      return this.report !== null ? this.report.createdBy === this.userId : false
    },

    isHuntLeader () {
      return this.hunt !== null ? this.hunt.huntLeaderUserId === this.userId : false
    },

    isMember () {
      const huntAreaId = this.report.huntAreaId
      return huntAreaId !== null ? this.$store.getters['huntarea/isMember'](huntAreaId) : false
    },

    isParticipant () {
      const participants = this.participants || []

      for (const participant of participants) {
        if (participant.userId === this.userId) {
          return true
        }
      }
      return false
    }
  },

  methods: {
    onClose () {
      this.$emit('on-close')
    },

    onEdit () {
      if (this.huntAreaId) {
        this.$router.push({ name: 'edit-report', params: { reportId: this.report.id } })
      } else {
        this.$router.push({ name: 'edit-report-no-huntarea',
          params: { reportId: this.report.id },
          query: { huntId: this.huntId }
        })
      }
    }
  }
}
</script>

<style scoped>
.report-toolbar {
  padding: 0.75rem;
  border-top: 1px solid #dbdbdb;
  background-color: #f5f5f5;
  margin-bottom: 0;
}
</style>
